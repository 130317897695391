import React, { useState } from 'react';
import Modal from '../components/modal';

var blocReferences = [
  {
    title: 'Allianz',
    description: 'Outils métier RH / Formation',
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/6p9WW05ErTtJppHaeB5j1A/ea023985e5457abd68f3222815d96e0c/Allianz.jpg?fm=webp&q=60&h=350',
    classRefblock:"black",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding">
<div id="carousel-allianz" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 no-padding" data-ride="carousel">
<ol class="carousel-indicators">
<li data-target="#carousel-allianz" data-slide-to="0" class=""></li>
<!--<li data-target="#carousel-allianz" data-slide-to="1" class="active"></li>
<li data-target="#carousel-allianz" data-slide-to="2" class=""></li>-->
</ol>
<div class="carousel-inner relative overflow-hidden col-xs-12 no-padding" role="listbox">
<div class="carousel-item active float-left w-full active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4tJiFw48Sqe1aoDtDqCBfc/69729c056f3245f87090aa4be9cac4bd/allianz-site-internet.jpg?fm=webp&q=60&h=350" alt="allianz site internet desktop" title="Site internet d'Allianz">
</div>
<!--
<div class="carousel-item active float-left w-full active float-left w-full active">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4cVxX98uY6FKmMUt69gPpk/d4955b48d05343caae1cde87391d4c60/allianz-site-mobile-responsive.jpg?fm=webp&q=60&h=350" alt="allianz site internet mobile" title="Site internet d'Allianz sur mobile">
</div>
<div class="carousel-item active float-left w-full active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/37jSmzvZNR1Ed8kp1IaZfj/3c10a42e03fc370d62e6420d2a6c388e/allianz-site-web-responsive.jpg?fm=webp&q=60&h=350" alt="allianz site web responsive" title="Site d'internet d'Allianz sur tablette, mobile et ordinateur">
</div>
-->
</div>
<a class="carousel-control-prev" href="#carousel-allianz" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
<span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carousel-allianz" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
<span class="sr-only">Next</span>
</a>
</div>
</div>
<div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8">
<div class="col-xs-12 no-padding ref-type">Outils métier RH / Formation</div>
<div class="col-xs-12 no-padding ref-name">Allianz</div>
<div class="col-xs-12 no-padding ref-desc">Nous avons développé et fait évoluer depuis de nombreuses années un<strong> outil métier </strong>qui permet de gérer et d'automatiser de nombreuses taches et de piloter l'activité des collaborateurs (mesure de la performance, e-learning, organisation des formations, reprographie, quizz online,...)</div>
<div class="col-xs-12 no-padding ref-desc-i"></div>
</div>
    `,
  },
  {
    title: 'Total',
    description: 'Outil métier de vente',
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/64WxggCTNRy87duCuJhotO/3ddcfdd9d79c98aae387e607d3923ba4/Total.jpg?fm=webp&q=60&h=350',
    classRefblock:"",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding h-full">
    <div id="carousel-total" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 h-full" data-ride="carousel">
    <ol class="carousel-indicators">
    <li data-target="#carousel-total" data-slide-to="0" class=""></li>
    <!--<li data-target="#carousel-total" data-slide-to="1" class=""></li>
    <li data-target="#carousel-total" data-slide-to="2" class="active"></li>-->
    </ol>
    <div class="carousel-inner relative overflow-hidden col-xs-12 no-padding  h-full" role="listbox">
    <div class="carousel-item active float-left w-full active float-left w-full h-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4rjRmSFuzyYGxEp9PxqTCH/590b58f2be37b0e93835aa0728949b58/total-application-web.jpg?fm=webp&q=60&h=350" alt="total application web outil metier" title="Application web sur mobile de l'outil metier de Total">
    </div>
    <!--
    <div class="carousel-item active float-left w-full active float-left w-full h-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/1tOEwhJVHLbeR0jyp6L7fa/ab52d5b35956d23afcbaa464a1fd20e2/total-outil-metier-desktop.jpg?fm=webp&q=60&h=350" alt="total outil metier desktop" title="L'outil metier de total">
    </div>
    <div class="carousel-item active float-left w-full active float-left w-full active h-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/6yx3U3J2MHZQCHqjQHTLJJ/d9962fc67aa6e36b875662f0e8ae2a87/total-outil-metier-web.jpg?fm=webp&q=60&h=350" alt="total outil metier web" title="L'outil metier de total sur navigateur">
    </div>
    -->
    </div>
    <a class="carousel-control-prev" href="#carousel-total" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
    <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-total" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
    <span class="sr-only">Next</span>
    </a>
    </div>
    </div>
    <div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8"> 
    <div class="col-span-12 no-padding ref-type">Outil métier de vente</div>
    <div class="col-span-12 no-padding ref-name">Total</div>
    <div class="col-span-12 no-padding ref-desc">Pour la marque Awango, nous avons développé une <strong>plateforme mobile</strong> couplée à une <strong>application web</strong> permettant de saisir des ventes de lampes solaire (dans le monde entier) en mode hors connexion et permet le pilotage de l'activité à la filiale grace à leur accès personnalisé au siège.</div>
    <div class="col-span-12 no-padding ref-desc-i"></div>
    </div>
  `,
  },
  {
    title: 'Crédit Foncier Immobilier',
    description: 'Outil métier & Plateforme de vente',
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/47X7kSKclknO8VMh2iYJAe/92e5aa29ec3ccf6a163523042d8ab401/CFI.jpg?fm=webp&q=60&h=350',
    classRefblock:"grey",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding h-full">
<div id="carousel-CFI" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 no-padding" data-ride="carousel">
<ol class="carousel-indicators">
<li data-target="#carousel-CFI" data-slide-to="0" class=""></li>
<li data-target="#carousel-CFI" data-slide-to="1" class=""></li>
<li data-target="#carousel-CFI" data-slide-to="2" class="active"></li>
<li data-target="#carousel-CFI" data-slide-to="3" class=""></li>
<li data-target="#carousel-CFI" data-slide-to="4" class=""></li>
</ol>
<div class="carousel-inner relative overflow-hidden col-xs-12 no-padding" role="listbox">
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/76cqg9eLsFFuO0NNloNb0c/222f5b11e229c46e1eaa4b1909993f4a/cfi-mobile-outil-metier.jpg?fm=webp&q=60&h=350" alt="application mobile cfi" title="L'application sur smartphone du Crédit Foncier Immobilier">
</div>
<!--<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4ASPckFUpr7P1J6ohGVH8x/78e059e7d3acfdf88d29b5b8856bc5d4/cfi-plateforme-vente.jpg?fm=webp&q=60&h=350" alt="plateforme vente cfi" title="La plateforme de vente du Crédit Foncier Immobilier">
</div>
<div class="carousel-item active float-left w-full active">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/3Otx3V6pegBBbncAuOJ9dC/02efd4ea8ae7d73b36d61d845df77730/cfi-web-outil-metier.jpg?fm=webp&q=60&h=350" alt="web outil metier cfi" title="La plateforme de vente du Crédit Foncier Immobilier sur mobile">
</div>
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/3TC1TlMOBF452qiCdpQLAG/657e21427a1357dae7a48f3da3e8fe58/cfi-plateforme-vente-mobile.jpg?fm=webp&q=60&h=350" alt="appli plateforme vente mobile cfi" title="L'application mobile du Crédit Foncier Immobilier">
</div>
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/1SNBHIFOoXaMPdV1Bg07YV/b29237848dd62230c0c085b61dbacc9c/cfi-outil-metier-smartphone.jpg?fm=webp&q=60&h=350" alt="application smartphone cfi" title="L'app du Crédit Foncier Immobilier">
</div>-->
</div>
<a class="carousel-control-prev" href="#carousel-CFI" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
<span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carousel-CFI" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
<span class="sr-only">Next</span>
</a>
</div>
</div>
<div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8"> 
<div class="col-xs-12 no-padding ref-type">Outil métier &amp; Plateforme de vente</div>
<div class="col-xs-12 no-padding ref-name">Crédit Foncier Immobilier</div>
<div class="col-xs-12 no-padding ref-desc">Ce projet a consisté au développement d'un<strong> site internet </strong>couplé à une<strong> application mobile</strong> visant à rendre plus simple la réservation ainsi que la vente de biens immobiliers.</div>
<div class="col-xs-12 no-padding ref-desc-i"></div>
</div>
    `,
  },
  {
    title: 'Alsei',
    description: 'Site internet et Catalogue digital',
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/3U7CMBiUI3IDGVSGbaMvas/0f047d880fd64ae6525960eb13a3f4d6/Alsei.jpg?fm=webp&q=60&h=350',
    classRefblock:"black",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding h-full">
<div id="carousel-alsei" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 no-padding" data-ride="carousel">
<ol class="carousel-indicators">
<li data-target="#carousel-alsei" data-slide-to="0" class=""></li>
<li data-target="#carousel-alsei" data-slide-to="1" class="active"></li>
<li data-target="#carousel-alsei" data-slide-to="2" class=""></li>
<li data-target="#carousel-alsei" data-slide-to="3" class=""></li>
<li data-target="#carousel-alsei" data-slide-to="4" class=""></li>
</ol>
<div class="carousel-inner relative overflow-hidden col-xs-12 no-padding" role="listbox">
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/5fS4PVi2Y9BuJCmqZeC7lR/76f321442c2fccd319f5b1b620dcfb7b/alsei-site-web-responsive.jpg?fm=webp&q=60&h=350" alt="site web responsive alsei" title="Le site internet responsive d'Alsei sur tablette">
</div>
<!--<div class="carousel-item active float-left w-full active">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/3ZQrHqX0y8A8JsuqjG6buc/e94671d02f84d93a6717ceb9fe5f1de6/alsei-application-mobile.jpg?fm=webp&q=60&h=350" alt="application smartphone alsei" title="L'application d'Alsei sur un iPad">
</div>
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/18IRqaRInKgFUiXxcmv86J/51f891525238f0bb658269330b0333b6/alsei-site-internet-tablette.jpg?fm=webp&q=60&h=350" alt="site internet alsei tablette" title="Le site web d'Alsei sur iPad">
</div>
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/3h7SXjYSsFNPzlHMWCjHLz/32b96173368d3c20e02cabc13de597d8/alsei-site-web-ordinateur.jpg?fm=webp&q=60&h=350" alt="site web alsei ordinateur" title="Le site internet d'Alsei sur ordinateur">
</div>
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/3jPhvzXtG5RFGwjeUUuN4G/5e6ae01e40253c610cd162648be4d2ee/alsei-website-refonte.jpg?fm=webp&q=60&h=350" alt="Fifth slide" title="Le site refondu d'Alsei sur un ordinateur">
</div>-->
</div>
<a class="carousel-control-prev" href="#carousel-alsei" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
<span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carousel-alsei" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
<span class="sr-only">Next</span>
</a>
</div>
</div>
<div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8"> 
<div class="col-xs-12 no-padding ref-type">Site internet et Catalogue digital</div>
<div class="col-xs-12 no-padding ref-name">Alsei</div>
<div class="col-xs-12 no-padding ref-desc">Nous avons opéré à la <strong>refonte de l'ancien site</strong> Alsei ainsi que le <strong>développement d'une application mobile</strong> reprenant l'ensemble des offres disponibles d'Alsei afin d'aider leurs commerciaux dans leurs présentations clients.</div>
<div class="col-xs-12 no-padding ref-desc-i"></div>
</div>
    `,
  },
  {
    title: 'Motralec',
    description: 'Refonte de site internet',
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/6MC5timuCccYlJnCsLIL9U/9c0c02875e2cd71700947eb0750c38e0/Motralec.jpg?fm=webp&q=60&h=350',
    classRefblock:"grey",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding h-full" style="padding-left:0;">
    <div id="carousel-motralec" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 no-padding" data-ride="carousel">
    <ol class="carousel-indicators">
    <li data-target="#carousel-motralec" data-slide-to="0" class=""></li>
    <li data-target="#carousel-motralec" data-slide-to="1" class="active"></li>
    <li data-target="#carousel-motralec" data-slide-to="2" class=""></li>
    <li data-target="#carousel-motralec" data-slide-to="3" class=""></li>
    </ol>
    <div class="carousel-inner relative overflow-hidden col-xs-12 no-padding" role="listbox">
    <div class="carousel-item active float-left w-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4G9ab0sBi8BLD0GBg5ch7o/e94d2e6c7f712531076c660ca80487bd/motralec-site-internet-tablette.jpg?fm=webp&q=60&h=350" alt="site internet motralec tablette" title="Le site internet de Motralec sur tablette">
    </div>
    <!--<div class="carousel-item active float-left w-full active">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/38h3zk2QRaJ95ObkASf521/b541a6dec95ed8775c9c4c6b79c88643/motralec-site-web-ordinateur.jpg?fm=webp&q=60&h=350" alt="site web motralec ordinateur" title="Le site web de Motralec sur ordinateur">
    </div>
    <div class="carousel-item active float-left w-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4pGb5OrTjGrhgOnQAjFp3F/00959a0dfca1b6eea8d3391065290337/motralec-site-internet-responsive.jpg?fm=webp&q=60&h=350" alt="site responsive motralec" title="le site responsive de Motralec sur un iPad">
    </div>
    <div class="carousel-item active float-left w-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/7xwgKrMLFPJ48YDNQy5Act/1ebdba3fb7c3dc0b814e41179fb59b1f/motralec-site-web-desktop.jpg?fm=webp&q=60&h=350" alt="website motralec ordinateur" title="Le site web de motralec sur un ordinateur">
    </div>-->
    </div>
    <a class="carousel-control-prev" href="#carousel-motralec" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
    <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-motralec" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
    <span class="sr-only">Next</span>
    </a>
    </div>
    </div>
    <div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8"> 
    <div class="col-xs-12 no-padding ref-type">Refonte de site internet</div>
    <div class="col-xs-12 no-padding ref-name">Motralec</div>
    <div class="col-xs-12 no-padding ref-desc">Motralec est un leader dans le domaine de la vente de pompes. Nous avons réalisés une <strong>refonte globale de leur site internet</strong> afin de le rendre compatible et au point par rapport aux exigences de <strong>Google</strong>.</div>
    <div class="col-xs-12 no-padding ref-desc-i"></div>
    </div>
    `,
  },
  {
    title: 'Securis',
    description: "Outil métier Organisation et pilotage de l'activité",
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/4U0yM7hf37KLJ3EPqEQ9AV/4a6e20f89886cd121806e0e22931871b/Vigirisk.jpg?fm=webp&q=60&h=350',
    classRefblock:"",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding h-full">
    <div id="carousel-securis" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 no-padding" data-ride="carousel">
    <ol class="carousel-indicators">
    <li data-target="#carousel-securis" data-slide-to="0" class=""></li>
    <li data-target="#carousel-securis" data-slide-to="1" class="active"></li>
    <li data-target="#carousel-securis" data-slide-to="2" class=""></li>
    </ol>
    <div class="carousel-inner relative overflow-hidden col-xs-12 no-padding" role="listbox">
    <div class="carousel-item active float-left w-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/59UQJHirlyTpUDWieDIZ7q/9c59305e4cf40cceee5fe5498bbfd9e2/securis-outil-metier-ordinateur.jpg?fm=webp&q=60&h=350" alt="outil metier securis desktop" title="L'outil metier de Securis sur un ordinateur">
    </div>
    <!--<div class="carousel-item active float-left w-full active">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4xlIaTif7AY8NM466mz5yN/af4a574a0aba8e3a920136f809962097/securis-outil-metier-tablette.jpg?fm=webp&q=60&h=350" alt="outil metier securis tablette" title="L'outil de Securis sur une tablette">
    </div>
    <div class="carousel-item active float-left w-full">
    <img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/PBhnZlG8GRt6RdTHifrwv/79934e4c39a84f3e272852d1079d96a7/securis-outil-metier.jpg?fm=webp&q=60&h=350" alt="securis outil metier" title="L'outil metier de Securis fait par l'agence web alphalives">
    </div>-->
    </div>
    <a class="carousel-control-prev" href="#carousel-securis" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
    <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-securis" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
    <span class="sr-only">Next</span>
    </a>
    </div>
    </div>
    <div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8"> 
<div class="col-xs-12 no-padding ref-type">Outil métier Organisation et pilotage de l'activité</div>
<div class="col-xs-12 no-padding ref-name">Securis</div>
<div class="col-xs-12 no-padding ref-desc">Sécuris est un des leaders du controle des extincteurs en France. Nous lui vons développé une <strong>application métier</strong> permettant de gérer, organiser, suivre et piloter l'activité de l'ensemble des salariés du groupe (Planning, dématérialisation des documents, rendez-vous, signature électronique, ...)</div>
<div class="col-xs-12 no-padding ref-desc-i"></div>
</div>
    `,
  },
  {
    title: 'Progassur',
    description: 'Solution SAAS assureurs',
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/1aq07fxFdTVxi1mjWnWDJ1/153f8261b4b98432c3dfe11a7b5efd95/Prograssur.jpg?fm=webp&q=60&h=350',
    classRefblock:"grey",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding h-full">
<div id="carousel-progassur" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 no-padding" data-ride="carousel">
<ol class="carousel-indicators">
<li data-target="#carousel-progassur" data-slide-to="0" class=""></li>
<li data-target="#carousel-progassur" data-slide-to="1" class="active"></li>
</ol>
<div class="carousel-inner relative overflow-hidden col-xs-12 no-padding" role="listbox">
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/6amJL5pUmjBDp6QJVPV3mt/b0baa0cf85f0b076ea526d125f207bae/progassur-solution-saas.jpg?fm=webp&q=60&h=350" alt="solution progassur saas" title="Solution SAAS développé par Alphalives pour Progassur">
</div>
<!--<div class="carousel-item active float-left w-full active">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/4z8KEcLxzBk54MJ4d9ntZg/3c2e9a817e025db9004b7cfed1e29811/progassur-solution-saas-ordinateur.jpg?fm=webp&q=60&h=350" alt="solution saas progassur ordinateur" title="Solution SAAS de Progassur sur un ordinateur">
</div>-->
</div>
<a class="carousel-control-prev" href="#carousel-progassur" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
<span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carousel-progassur" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
<span class="sr-only">Next</span>
</a>
</div>
</div>
<div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8"> 
<div class="col-xs-12 no-padding ref-type">Solution SAAS assureurs</div>
<div class="col-xs-12 no-padding ref-name">Progassur</div>
<div class="col-xs-12 no-padding ref-desc">Progassur est une <strong>solution développée</strong> par Alphalives qui permet de gérer les contrats d'assurance ainsi que les sinistres associés. Progassur commence a être largement diffusé auprès de gestionnaires de parcs immobiliers et OPH.</div>
<div class="col-xs-12 no-padding ref-desc-i"></div>
</div>
    `,
  },
  {
    title: 'Je sors avec bébé',
    description: 'Site web & Application mobile',
    urImg: 'https://images.ctfassets.net/a39bg4ghqkpe/3RxIiF2Rj8VVenA3Ayv2Q9/0305101d9c9cd01e270704a3f3eda824/JesorsavecBB.jpg?fm=webp&q=60&h=350',
    classRefblock:"grey",
    modalContent: `
    <div class="lg:col-span-9 col-span-12 no-padding h-full">
<div id="carousel-jesorsavecbebe" class="carousel slide carousel-fade relative col-xs-12 lg:pr-2 no-padding" data-ride="carousel">
<ol class="carousel-indicators">
<li data-target="#carousel-jesorsavecbebe" data-slide-to="0" class=""></li>
<li data-target="#carousel-jesorsavecbebe" data-slide-to="1" class=""></li>
<li data-target="#carousel-jesorsavecbebe" data-slide-to="2" class=""></li>
<li data-target="#carousel-jesorsavecbebe" data-slide-to="3" class=""></li>
<li data-target="#carousel-jesorsavecbebe" data-slide-to="4" class="active"></li>
</ol>
<div class="carousel-inner relative overflow-hidden col-xs-12 no-padding" role="listbox">
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/5657UbR6ZVi3yaRSIChhuA/9047514a96e0032bb0c83c753f0a410f/je-sors-avec-bebe-site-internet-ordinateur.jpg?fm=webp&q=60&h=350" alt="site internet je sors avec bebe" title="Le site internet de Je Sors Avec Bébé développé par Alphalives">
</div>
<!--<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/RgTyfpDabRyeQH1HHDeZA/9333f95b5d729e5e789c766e739fecc5/je-sors-avec-bebe-site-web-ordi-smartphone.jpg?fm=webp&q=60&h=350" alt="site web appli mobile je sors avec bebe" title="Le site internet et l'appication mobile de Je Sors Avec Bébé">
</div>
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/5Mko09pkg8Li5nb8dQaMVl/32963f02d08a8cfd4e5b287d1b28ec06/je-sors-avec-bebe-site-internet-tablette.jpg?fm=webp&q=60&h=350" alt="site internet tablette je sors avec bebe" title="Le site internet de Je Sors Avec Bébé sur tablette">
</div>
<div class="carousel-item active float-left w-full">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/1A3ro1ZO3me75jbw1zT15L/f23538242d5398dff2ac4d03324ffbe3/je-sors-avec-bebe-site-web-tablette.jpg?fm=webp&q=60&h=350" alt="site web je sors avec bebe" title="Le site internet dévelopé par Alphalives de Je Sors Avec Bébé sur tablette">
</div>
<div class="carousel-item active float-left w-full active">
<img class="block w-full img-fluid refImg" src="https://images.ctfassets.net/a39bg4ghqkpe/1KAb8IasL0vamPZCTlG3EG/1a2210726856407ddb9df4c582a6fbb7/je-sors-avec-bebe-website-ordinateur-portable.jpg?fm=webp&q=60&h=350" alt="site internet je sors avec bebe ordinateur portable" title="Le site web de Je Sors Avec Bébé sur un ordinateur portable">
</div>-->
</div>
<a class="carousel-control-prev" href="#carousel-jesorsavecbebe" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
<span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carousel-jesorsavecbebe" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
<span class="sr-only">Next</span>
</a>
</div>
</div>
<div class="lg:col-span-3 col-span-12 modal-text px-2 py-2 relative md:pt-8"> 
<div class="col-xs-12 no-padding ref-type">Site web &amp; Application mobile</div>
<div class="col-xs-12 no-padding ref-name">Je sors avec bébé</div>
<div class="col-xs-12 no-padding ref-desc">Je sors avec bébé est une startup qui propose de noter les établissement en fonction de leurs services "adaptés ou non aux nourissons". Nous avons <strong>développé leur site internet</strong> ainsi qu'une <strong>application mobile</strong> avec création de compte, géolocalisation, notation,...</div>
<div class="col-xs-12 no-padding ref-desc-i"></div>
</div>
    `,
  },
]
export default function BlocReferences({filter, limit}) {
  var [activeModal, setActiveModal] = useState(undefined);
  
  if(filter){
    blocReferences = blocReferences.filter((value) => {
        return filter.includes(value.title)
    })
  }
  if(limit){
    if(!filter){
      blocReferences.sort(() => Math.random() - 0.5);
    }
    blocReferences.length = limit;
  }
  //console.log(blocReferences)
  return (
    <>
      {blocReferences.map((item, index) => (
        <div key={index}>
          <div onClick={() => { setActiveModal(index) }}  style={{cursor:"pointer"}} title={"Réference "+item.title} >
            <div
              className={"ref-block "+ item.classRefblock}
              style={{ backgroundImage: " url("+item.urImg+")" }}>
              <div className="bg-color">
                <span>
                  <div className="ref-name">{item.title}</div>
                </span>
                <span>
                  <div className="ref-type">{item.description}</div>
                </span>
                <div className="ref-go">
                  <span>Découvrir</span> <div className="ref-hr"></div>
                </div>
              </div>
            </div>
          </div>
          <Modal
          onClose={(index) => { if(index) setActiveModal(undefined)}}
          show={activeModal == index ? true : false}
          html={{__html : item.modalContent}}
          index={index+1}
          >
            
          </Modal>
          
        </div>
      ))}
    </>
  )
}
